import store from '@/store'
import { useRouter } from '@core/utils/utils'
export const isProductInCart = (productId) => {
  //console.log("store",store.state.cart.carts);

  let item = store.state.cataloge.carts.find(cart => cart.items.find(n => n.uuid === productId));

  //   console.log("item",item);
  if (item == undefined) return false;

  return true;
  // return store.state.cart.carts.filter(item => item.uuid == productId);
}

export const useCataloge = () => {
  // eslint-disable-next-line arrow-body-style
  const addProductInWishlist = productId => {
    return store.dispatch('cataloge/addProductInWishlist', { productId })
  }

  // eslint-disable-next-line arrow-body-style
  const removeProductFromWishlist = productId => {
    return store.dispatch('cataloge/removeProductFromWishlist', { productId })
  }

  // eslint-disable-next-line arrow-body-style
  const addProductInCart = productId => {
    return store.dispatch('cataloge/addProductInCart', { productId })
  }
  const updateQuantity = params => {
    return store.dispatch('cataloge/updateQuantity', { params })
  }
  // eslint-disable-next-line arrow-body-style
  const removeProductFromCart = productId => {
    return store.dispatch('cataloge/removeProductFromCart', { productId })
  }
  const removeItemFromCart = productId => {
    return store.dispatch('cataloge/removeItemFromCart', { productId });
  }
  return {
    addProductInWishlist,
    removeProductFromWishlist,
    addProductInCart,
    removeProductFromCart,
    removeItemFromCart,
    updateQuantity,
  }
}

export const useEcommerceUi = () => {
  const { router } = useRouter()

  const toggleProductInWishlist = product => {
    const { addProductInWishlist, removeProductFromWishlist } = useCataloge()
    if (product.isInWishlist) {
      removeProductFromWishlist(product.uuid).then(() => {
        // eslint-disable-next-line no-param-reassign
        product.isInWishlist = false
      })
    } else {
      addProductInWishlist(product.uuid).then(() => {
        // eslint-disable-next-line no-param-reassign
        product.isInWishlist = true
      })
    }
  }
  const updateQuantityHandler = (product) => {
    const { updateQuantity } = useCataloge()
    updateQuantity(product).then((res) => {
      // eslint-disable-next-line no-param-reassign
      product = { ...res.data }
      fetchAllCarts()
      return product;
    })

  }
  const handleCartActionClick = product => {
    const { addProductInCart } = useCataloge()

    if (product.isInCart) {
      router.push({ name: 'cataloge-checkout' })
    } else {
      addProductInCart({
        uuid: product.uuid,
        quantity: product.quantity ?? 1
      }).then(() => {
        // eslint-disable-next-line no-param-reassign
        product.isInCart = true
        fetchAllCarts();
        // Update cart items count
        store.commit('cataloge/UPDATE_CART_ITEMS_COUNT', store.state['cataloge'].cartItemsCount + 1)
      })
    }
  }
  const fetchAllCarts = () => {


    store.dispatch(`cataloge/fetchCartProducts`)
      .then((response) => {
        var carts = response.data.products;
        var items = [];
        const itemslist = carts.map(n => {
          items = [...items, ...n.items];
        });

        // const items = property => element => element[property] 
        store.commit(
          "cataloge/UPDATE_CART_ITEMS_COUNT",
          items.length
        );

        store.commit("cataloge/setCartNav", items);
        store.commit("cataloge/setCarts", carts);
      });
  }

  const handleWishlistCartActionClick = (product, removeProductFromWishlistUi) => {
    const { addProductInCart, removeProductFromWishlist } = useCataloge()

    if (product.isInCart) {
      router.push({ name: 'cataloge-checkout' })
    } else {
      addProductInCart({
        uuid: product.uuid,
        quantity: product.quantity ?? 1
      })
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          product.isInCart = true
          removeProductFromWishlist(product.uuid)

          // Update cart items count
          store.commit('cataloge/UPDATE_CART_ITEMS_COUNT', store.state['cataloge'].cartItemsCount + 1)
        })
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          product.isInWishlist = false
          removeProductFromWishlistUi(product)
        })
    }
  }
  return {
    toggleProductInWishlist,
    handleCartActionClick,
    handleWishlistCartActionClick,
    updateQuantityHandler,
    fetchAllCarts,
  }
}
